@import '../../styles/base/variables.scss';

.rolling-header {
  width: 100%;
  height: 100px;
  margin-top: $header-height;
  background-image: url('../../images/logo/ocean-archive-white.svg'), url('../../images/logo/ocean-archive-white.svg');
  // background-color: $dark-blue;
  background-repeat: no-repeat;
  background-size: 280px auto;
  background-position: -124px center, calc(100% + 156px) center;
  animation: homeIntroLogo 2s .5s ease forwards;
  z-index: 1;
  
  &--dark {
    background-image: url('../../images/logo/ocean-archive-dark.svg'), url('../../images/logo/ocean-archive-dark.svg');
  }

  &--absolute {
    top: $header-height;
    left: 0;
    right: 0;
    width: 100%;
    position: absolute;
  }

  &--no-margin {
    margin-top: 0;
  }

  .modal & {
    display: none;
  }
}
