@function rem($pixels) {
  @return calc($pixels / 16px) * 1rem;
}

$page-margin: rem(50px);
$page-margin-mobile: rem(25px);

$header-height: rem(60px);
$newsflash-height: rem(44px);

// COLOURS

// GREENS
$dark-green: #3fb39a;

// GRAYS
$soft-gray: #eeeeee; // main bg gray
$mid-gray: #dddddd; // input focus background
$dark-gray: #495057;
$subline-gray: #7e7e7e;

$item-details-border: #787878; // input focus background

// REDS
$red: #ef5d60;
$input-error: #ef5d60;

// BLUES
$dark-blue: #142636;
$soft-blue: #d2d7dc;

// FOCUS COLOURS
$focus_arts: #0076ff;
$focus_scitech: #9013fe;
$focus_action: #50e3c2;

$soft-violet: #DEC4FF;

$white-30: rgba(255, 255, 255, 0.3);

:root {
  --header-height: #{$header-height};
  --page-margin: #{$page-margin};
  @media (max-width: 991px) {
    --page-margin: #{$page-margin-mobile};
  }
}
