@import '../../../styles/base/variables';
@import '../../../styles/base/mixins';

.profile-editor {
  padding-bottom: rem(32px);

  .modal & {
    padding: 0;
    h1 { display: none }
  }

  &__left {
    position: sticky;
    top: calc(var(--header-height) + 24px);

    @include media-breakpoint-down(md) {
      padding-top: rem(32px);
    }
  }
    .modal &__left {
      top: 0;
      padding: 0;
    }

  &__right {
    padding-top: rem(54px);

    @include media-breakpoint-down(md) {
      margin-top: rem(32px);
    }
  }
    .modal &__right {
      padding-top: 0;
    }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    gap: rem(16px);
    margin-top: rem(32px);

    @include media-breakpoint-down(md) {
      justify-content: center;
    }

    .changePassword {
      width: 100%
    }
  }

  &__image {
    aspect-ratio: 1;
    cursor: pointer;

    div {
      height: 100%;
      background-color: $soft-gray;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: rem(6px);
    }

    img {
      width: 100%;
      border-radius: rem(6px);
    }
  }

  &__save {
    margin-top: rem(32px);

    @include media-breakpoint-down(md) {
      text-align: center;
    }
  }

  h1 {
    font-size: 32px;
  }

  textarea {
    min-height: rem(160px);
  }

  .upload {
    background-color: $dark-gray;
    cursor: pointer;
    border-style: solid;
    border-width: 1px;
    border-color: $mid-gray;
  }
}
