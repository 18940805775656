@import '../base/variables';

#liveStreamModal {
  justify-content: center;
  max-width: 100%;
  @media screen and (min-width: 576px) {
    max-width: 90%;
  }
  @media screen and (min-width: 960px) {
    max-width: 80vw;
  }

  .modal-content {
    background: transparent;
    border: 0;
    width: fit-content;
    height: fit-content;

    .closeButton {
      cursor: pointer;
      color: #fff;
      position: absolute;
      right: -40px;
      top: 0;
      background-color: $dark-blue;
      width: rem(40px);
      height: rem(40px);

      &:hover {
        background-color: $focus_scitech;
      }
    }
  }
}
