@import '../../styles/base/variables.scss';
@import '../../styles/base/mixins.scss';

@keyframes searchIntroLogo {
  from { background-position: -124px center, calc(100% + 156px) center; }
  to { background-position: -62px center, calc(100% + 218px) center; }
}

.search-intro {
  color: $soft-gray;
  font-size: rem(24px);
  text-align: center;
  font-family: Amare Medium, sans-serif;
  height: rem(250px);
  padding: rem(60px) 0 rem(25px);
  background-color: $dark-blue;
  border-bottom: .5px solid #fff;

  @include media-breakpoint-down(sm) {
    padding-bottom: rem(18px);
  }

  &__text {
    h1 {
      @include headlineFont;
      @include media-breakpoint-down(xs) {
        font-size: 28px;
      }
    }
  }

  &__input {
    top: rem(90px);
    width: 100%;
    max-width: 558px;
    position: sticky;
    padding: 0 var(--page-margin);
    margin: rem(40px) auto 0;
    display: flex;
    align-items: center;

    @include media-breakpoint-down(sm) {
      top: rem(78px);
    }

    input {
      color: #fff;
      font-size: 20px;
      width: 100%;
      height: rem(43px);
      padding: rem(2px) rem(12px) 0;
      border: 1px solid #fff;
      background-color: transparent;
      appearance: none;
      font-family: Amare Alta, sans-serif;

      &:focus {
        outline: 1px solid #fff;
      }

      .os-win & {
        padding: 0 rem(12px) rem(4px);
      }
    }

    &-icon {
      right: calc(10px + var(--page-margin));
      position: absolute;
      pointer-events: none;
    }
  }

}