@import 'styles/base/variables.scss';
@import 'styles/base/mixins.scss';

.admin-search {
  &.form-group {
    margin-bottom: 0;
  }

  &__bar {
    width: 100%;
    max-width: 680px;
    margin: rem(40px) auto 0;
    position: relative;
  }

  &__fields {
    display: flex;
    align-items: center;
    position: relative;

    #searchBy {
      height: rem(44px);
      margin-right: rem(6px);

      button {
        padding: 3px 15px 0;
        @include sansBodyFont;
      }
    }

    input {
      color: #fff;
      font-size: 20px;
      width: 100%;
      height: rem(43px);
      padding: rem(2px) rem(12px) 0;
      border: 1px solid #fff;
      background-color: transparent;
      appearance: none;
      font-family: Amare Alta, sans-serif;

      &:focus {
        outline: 1px solid #fff;
      }

      .os-win & {
        padding: 0 rem(12px) rem(4px);
      }
    }
  }

  &__submit {
    right: rem(10px);
    position: absolute;
  }

  &__results {
    position: absolute;
    top: rem(50px);
    width: 100%;
    color: $dark-blue;
    background-color: $soft-gray;
    padding: rem(10px) 0;
    @include sansBodyFont;
  }

  &__result {
    padding: rem(9px) rem(15px) rem(6px);
    display: flex;
    cursor: pointer;

    &:hover {
      background-color: $soft-blue;
    }

    span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &:first-child {
        width: 70%;
      }
      &:last-child {
        text-align: right;
        flex: 1;
        opacity: 0.5;
        @include sansBodyCompactFontLight;
      }
    }
  }

  &__table {
    .react-bootstrap-table {
      overflow-x: auto;
      margin-bottom: 20px;
    }

    .react-bootstrap-table-pagination {
      justify-content: space-between;

      > div {
        margin-bottom: 10px;
        flex: 0 !important;
        &:first-child {
          padding-right: 0 !important;
        }
        &:last-child {
          padding-left: 0 !important;
        }
      }

      #pageDropDown {
        padding: 12px 12px 8px !important;
      }

      a {
        @include sansBodyCompactFont;
        border-radius: 0 !important;
      }
    }

    .table {
      @include sansBodyCompactFontLight;
      border-bottom: 0.5px solid $soft-gray;
      min-width: 800px;
      @media (max-width: 767px) {
        width: auto;
      }
  
      tr {
        td, th {
          border-top: 0.5px solid $soft-gray;
          &:last-child {
            text-align: right;
          }
        }
        th {
          @include sansBodyCompactFont;
          cursor: pointer;
        }
        td {
          &:last-child {
            white-space: nowrap;
            button, a {
              @include sansBodyFont;
              @media (min-width: 768px) {
                opacity: 0;
              }
            }
          }
          &:first-child span {
            display: inline-block;
            width: 30px;
          }
          &:nth-child(2) {
            @include sansBodyCompactFont;
          }
        }
      }
      tr:hover {
        td {
          &:last-child {
            button, a {
              opacity: 1;

              &:disabled {
                opacity: 0.5;
                cursor: not-allowed;
              }
            }
          }
        }
      }
    }
  }
}