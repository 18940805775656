@import '../../styles/base/variables.scss';
@import '../../styles/base/mixins.scss';

.search {
  &-header {
    top: 0;
    left: 0;
    width: 100%;
    position: fixed !important;
    pointer-events: none;
    z-index: 20;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &-sticky-wrapper {
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    position: absolute;
    z-index: 10;

    > div {
      top: -90px;
      width: 100vw;
      position: sticky;
      margin-top: $header-height;

      @include media-breakpoint-down(sm) {
        top: -110px;
      }
    }
  }

  &-body {
    display: flex;
    position: relative;
    padding: rem(375px) 0 4rem;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }

    .theme-switch {
      top: rem(330px);
      right: 1rem;
      position: absolute;
    }

    &__filter-btn {
      left: 1rem;
      top: rem(330px);
      position: absolute;
      color: white !important;

      @include media-breakpoint-up(lg) {
        display: none !important;
      }
      
      .theme-light & {
        color: #000 !important;
      }

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}