@import 'styles/base/variables.scss';

.itemEditor {
  position: relative;
  //color: #fff;
  width: 100%;
  .collapse {
    width: 100%;
  }
  .overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background: #081017;
    opacity: 0;
    z-index: 0;
    transition: opacity 1s ease-in;

    &.show {
      z-index: 99;
      opacity: 1;
    }
  }
  .accessDenied {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,0.2);
    opacity: 0;
    z-index: 0;
  }

  .sticky {
    position: sticky;
    top: 0;
    z-index: 0;
  }

  .fileReplace {
    margin: 0;
    cursor: pointer;

    &.active svg {
      color: $dark-green !important;
    }

    input {
      display: none;
    }
  }

  button.btn.location {
    svg {
      margin-top: -5px
    }
  }
}
