@import '../../../styles/base/variables';
@import '../../../styles/base/mixins';

.ripples {
  position: relative;

  &__layouts {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: rem(20px);
    overflow-x: auto;
    width: 100%;
    height: rem(85px);
    left: 0;
    transition: all 300ms ease;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    &::-webkit-scrollbar { display: none }
    &.enter-from, &.leave-to {
      height: 0;
      opacity: 0;
    }

    > div {
      cursor: pointer;
      scroll-snap-align: start;
      &:last-child { scroll-snap-align: end }

      &:hover, &.active {
        color: $focus_scitech;
        background-color: rgba($color: $focus_scitech, $alpha: 0.1);
      }
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 50% 50%;
    aspect-ratio: 2.33;
    width: 100%;
    position: relative;

    &.single-col { grid-template-columns: 100%; }

    @include media-breakpoint-down(sm) {
      grid-template-columns: 100%;
      aspect-ratio: auto;
    }

    // &::before {
    //   content: "";
    //   @include absoluteCover;
    //   @include loadingShimmer;
    // }
  }

  &__item {
    width: 100%;
    background-color: $white-30;
    position: relative;
    overflow: hidden;
    display: block;

    aspect-ratio: 1.165;
    .count-2 > & { aspect-ratio: 2.33 }
    .single-col & { aspect-ratio: 2.33 }

    // outline: 2px solid $dark-blue;
    // outline-offset: -1px;

    &-image {
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &::after {
        content: '';
        @include absoluteCover;
        background: linear-gradient(to top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
        opacity: 0;
        transition: opacity 300ms ease;
      }
    }
      &:hover &-image::after {
        opacity: 1;
      }

      &.empty &-image {
        &::after { background: transparent }
      }

    &-content {
      @include absoluteCover;
      padding: rem(30px) rem(15px) rem(15px);
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      // overflow: hidden;
      text-overflow: ellipsis;
      transform: translate3d(0, 100%, 0);
      transition: all 300ms ease;
      mask-image: linear-gradient(black 80%, transparent 92%);

      h3 {
        font-size: rem(22px);
      }
    }
      &:hover &-content {
        transform: none;
      }

    &-actions {
      height: rem(45px);
    }

    &-description {
      font-size: rem(14px);
      line-height: 1.2;
      overflow: hidden;
    }

    &-edit {
      @include absoluteCover;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: rem(15px);
      opacity: 0;
      &:hover {
        opacity: 1;
      }
    }
  }
    &--editing &__item {
      cursor: pointer;

      &.empty {
        outline: 1px solid $dark-blue;
        outline-offset: -0.5px;
      }
      &-image::after {
        background: rgba(0, 0, 0, 0.6) !important;
      }
      &-content {
        display: none;
      }
    }

  &__left, &__right {
    display: grid;
    &:empty { display: none; }

    &.count {
      &-1 { display: block; }
      &-2 { grid-template: 50% / 100%; }
      &-4 { grid-template: 50% 50% / 50% 50%; }
    }
  }

  &-placeholder {
    @include sansBodyFont;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white-30;
    border-radius: 5px;
    box-shadow: 0 0 0 15px $white-30;
    cursor: pointer;
    aspect-ratio: 2.33;

  }

  .loader {
    @include absoluteCover;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: rem(20px);
  }

  &.loading &__layouts,
  &.loading &__content {
    opacity: 0.15;
  }

  &__overlay {
    @include absoluteCover;
  }
}
