@import '../base/variables';

.alert {
  padding-top: rem(14px);
  z-index: 100;

  &.sticky {
    position: sticky;
    top: calc($header-height + 20px);
    max-width: calc(100vw - 40px);
    margin: 0 auto;
    font-family: Amare Alta, sans-serif;
  }
}