@import "../../base/variables";

.signUp {

  .loaderButton {
    height: 52px;
  }

  .validation {
    color: $input-error
  }
}
