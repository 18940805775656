@import "../base/variables";

html, body {
  min-height: 100vh;
  background-color: $soft-gray;
  color: #000;

  font-family: 'Minion Pro';

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.searchOpen {
    overflow: hidden;
  }

  // overflow-x: hidden;
  position: relative;

  &:has(.theme-dark) {
    background: $dark-blue !important;
  }
}

body > iframe {
  display: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Amare Alta', sans-serif;
}

h1 {
  font-size: 40px;
}

/* hack for finding flex issues
* {
  outline: 1px solid #f00 !important;
}
*/