@import '../../styles/base/variables.scss';
@import '../../styles/base/mixins.scss';

.editable-textblock {
  --focus-bg: rgba(255, 255, 255, 0.3);
  position: relative;

  &__content {
    outline-offset: 20px;
    outline-color: transparent;
    transition: background-color 200ms ease, box-shadow 200ms ease, outline-color 200ms ease, outline-offset 200ms ease, opacity 200ms ease, max-width 200ms ease;
    box-shadow: 0 0 0 10px transparent;
    min-height: rem(60px);
    border-radius: rem(5px);
    position: relative;
    max-width: rem(900px);

    &[contenteditable="true"],
    &[contenteditable="plaintext-only"], &.empty {
      background-color: var(--focus-bg);
      box-shadow: 0 0 0 15px var(--focus-bg);
      cursor: text;
      
      &:focus, &:focus-visible {
        outline: 2px solid $focus_scitech;
        outline-offset: 15px;
      }
    }

    &.empty {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 100%;
      cursor: pointer;
    }
  }
    &.loading &__content {
      opacity: 0.15;
    }

  .loader {
    left: 50%;
    top: calc(50% + rem(40px));
    transform: translate(-50%, -50%);
    position: absolute;
  }

}