@import '../../styles/base/variables.scss';
@import '../../styles/base/mixins.scss';

.waved-headline {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;

  h2 { margin: 0 }

  &__actions {
    display: flex;
    align-items: flex-start;
    padding-top: rem(2px);

    button:hover {
      color: $focus_scitech;
    }
  }
}