@import '../../styles/base/variables.scss';
@import '../../styles/base/mixins.scss';

.footer {
  color: white;
  font-size: 17px;
  font-family: Amare Alta, sans-serif;
  padding: 0 var(--page-margin);
  background-color: $dark-blue;
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    @include rainbowLine;
    bottom: auto;
    top: 0;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      > *:first-child {
        margin-bottom: rem(30px);
      }
    }
  }

  &__social {
    a {
      color: white;
    }

    svg {
      height: rem(28px);
      width: auto;
    }

    > *:not(:last-child) {
      margin-right: rem(15px);
    }
  }

  &__content {
    padding: rem(50px) 0 rem(30px);
  }

  &__columns {
    display: flex;
    margin-top: rem(60px);

    > div {
      flex: 1;
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column;

      > div:not(:last-child) {
        margin-bottom: rem(30px);
      }
    }
  }
  &__contact {
    @include media-breakpoint-down(sm) {
      a {
        display: block;
      }
    }
  }
  &__connect {
    text-align: center;
  }
  &__how-to {
    text-align: right;
  }

  &__author {
    text-align: center;
    font-size: rem(14px);
  }

  &__tba21 {
    margin: rem(60px) 0 rem(20px);

    @include media-breakpoint-down(sm) {
      text-align: center;
    }

    a {
      display: inline-block;
    }
  }

  p {
    margin-bottom: rem(14px);

    @include media-breakpoint-down(sm) {
      text-align: center;
      margin-bottom: rem(10px);
    }
  }
}
