@import 'styles/base/variables.scss';
@import 'styles/base/mixins.scss';

.admin-settings {
  table {
    border: none;

    th, td {
      vertical-align: middle;
      border-color: $soft-gray !important;
      border-width: .5px !important;
      font-family: Amare Alta, sans-serif;
    }

    input[type="file"] {
      display: none;
    }

    .theme-dark & {
      color: $soft-gray;
    }
  }

  .clickable-image {
    cursor: pointer;
  }

  .alert {
    position: sticky;
    top: calc($header-height + 20px);
  }
}