@import '../../styles/base/variables.scss';
@import '../../styles/base/mixins.scss';

@keyframes homeIntroLogo {
  from { background-position: -124px center, calc(100% + 156px) center; }
  to { background-position: -62px center, calc(100% + 218px) center; }
}

.home-intro {
  color: $soft-gray;
  font-size: 24px;
  text-align: center;
  font-family: Amare Medium, sans-serif;
  padding: 0 0 rem(80px);
  background-color: $dark-blue;

  &__text {
    padding: 0 var(--page-margin);

    @include media-breakpoint-down(sm) {
      margin-top: rem(25px);
    }
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 840px;
    margin: 60px auto 0;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }

    .button {
      margin: 10px;
    }
  }
}