.min-h-100 {
  min-height: 100vh;
}

.navbar-toggler {
  padding: 0
}

.navbar-brand {
  padding: 0
}
