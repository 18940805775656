#logo {
  background: #fff;
  width: 100%;
  height: 100vh;
  padding-top: 50px;
  transform: translate(0, 0);
  transition: transform .5s cubic-bezier(0.22, 0.61, 0.36, 1), opacity .2s linear;
  overflow-x: hidden;
  z-index: 12;

  @media screen and (min-width: 768px) {
    padding-top: 20px;
  }

  &.loaded {
    padding-top: 0;
    transform: translate3d(0,0,0);
    transition: transform .5s cubic-bezier(0.22, 0.61, 0.36, 1), opacity .2s linear, height .7s;
    mix-blend-mode: difference;
    background: transparent !important;
    height: 43px;
    .right, .left {
      filter: none;
    }
  }

  &.inv {
    position: sticky;
    top: 0;
  }

  header {
    width: 100%;
    z-index: 11;
    overflow-x: hidden;

    .left {
      left: 0;
      margin-left: -80vw;
      &.init {
        &.show {
          margin-left: -6vw;
          opacity: 1;
        }
      }
    }

    .right {
      opacity: 0;
      right: -16em;
      margin-right: calc(58vw + 21.5px); // Plus half the size of the logo (43);

      &.op {
        opacity: 1;
      }
      &.init {
        &.show {
          margin-right: 6vw;
          opacity: 1;
        }
      }
    }

    .left, .right {
      filter: invert(1);
      width: 16em;
      position: absolute;
      transition: margin 1s cubic-bezier(0.22, 0.61, 0.36, 1) .1s, opacity .8s linear;
    }
  }
}
