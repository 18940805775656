@import "../base/variables";
@import "../base/mixins";

// Hide nav on fixed body (When the logo is loading)
#body.fixed {
  margin-bottom: 0;
  #navigation {
    border-bottom: 0;
    overflow: hidden;
  }
  #searchConsole {
    border-bottom: 0;
    overflow: hidden;
  }
}

.home svg {
  fill: $focus_arts;
  transition: all 200ms ease;
}
.home:hover svg {
  fill: $soft-gray;
}

.nav-link {
  font-family: Amare Alta, sans-serif;
}

#navigation {
  background: #142636;
  //border-bottom: 1px solid #eee;

  overflow: unset;

  .navbar {
    height: $header-height;
    padding: 0.5rem 1rem calc(0.5rem + 5px);
    background: #142636;

    &-shifted {
      top: rem(44px);
    }

    .navbar-nav {
      display: flex;
      align-items: baseline;

      @media screen and (max-width: 768px) {
        margin-top: 2rem;
      }
    }

    .navbar-toggler {
      display: block;
      border: none;
      padding-left: 0;
      &:hover,
      &:focus {
        outline: none;
      }
      @media screen and (min-width: 768px) {
        display: none;
      }
    }

    .navbar-collapse {
      @media screen and (max-width: 768px) {
        // width: 100%;
        right: 0;
        top: $header-height;
        position: absolute;
        padding: 0 rem(20px) rem(10px);
        background-color: $dark-blue;

        a {
          color: white !important;
        }
      }
    }

    button {
      outline: none;
      color: $subline-gray;
      background-color: inherit;
      border: none;
      line-height: 0;
      display: inline-block;
    }
    .nav-item {
      line-height: 0;
      @media screen and (max-width: 768px) {
        width: 100%;
        text-align: right;
        margin-bottom: rem(20px) !important;
      }
      .nav-link {
        font-family: Amare Alta, sans-serif;
        text-transform: uppercase;
        // @media screen and (max-width: 992px) {
        //   padding-left: 0.6rem;
        // }
        font-size: 15px;
      }
      .nav-link.rem-padding {
        padding-top: 0.05rem;
        padding-bottom: 0.05rem;
      }
      .nav-link.live {
        color: $subline-gray;
      }
      .nav-link.pulsing {
        animation:pulsingText 3s linear infinite;
      }
      @keyframes pulsingText{
          0%{ color: $subline-gray; }
          8%{ color: #857c7a; }
          15%{ color: #857c7a; }
          23%{ color: #957870; }
          31%{ color: #b16d5c; }
          38%{ color: #cd5c42; }
          46%{ color: #de4d31; }
          54%{ color: red; }
          62%{ color: #de4d31; }
          69%{ color: #cd5c42; }
          77%{ color: #b16d5c; }
          85%{ color: #957870; }
          92%{ color: #857c7a; }
          100%{ color: $subline-gray}
      }
      .btn {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        background-color: #142636;
        box-shadow: none;
        color: $subline-gray;
        &.active {
          color: $soft-gray;
        }
        &:hover {
          color: $soft-gray;
        }
        &:focus {
          outline: none;
        }
      }
      a.nav-link {
        color: $subline-gray;
        &.active {
          color: $soft-gray;
        }
        &:hover {
          color: $soft-gray;
        }
        &:focus {
          outline: none;
        }
      }
      &.dropdown {
        &.show > a {
          color: $soft-gray;
        }
        .dropdown-menu {
          border: none;
          background: $dark-blue;

          @media screen and (max-width: 768px) {
            text-align: right;
            min-width: auto;
          }

          .dropdown-item {
            font-family: Amare Alta, sans-serif;
            text-transform: uppercase;
            color: $subline-gray;

            @media screen and (max-width: 768px) {
              text-align: right;
              padding: 0;
              padding-right: 1rem;
            }

            &:hover {
              color: $soft-gray;
              text-decoration: none;
              background-color: transparent;
            }
            &:focus {
              outline: none;
            }

            .nav-item {
              @media screen and (min-width: 768px) {
                padding: 0.125rem 0.25rem;
              }
            }
          }
        }
      }
    }
  }
}

.searchPadding {
  @include rainbowLine;
}