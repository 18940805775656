@import '../base/variables';

#video {
  background: #fff;
  width: 100%;
  height: 100vh;
  transform: translate(0, 0);
  transition: transform .5s cubic-bezier(0.22, 0.61, 0.36, 1), opacity .2s linear;
  overflow-x: hidden;
  z-index: 12;

  &.loaded {
    padding-top: 0;
    transform: translate3d(0,0,0);
    transition: transform .5s cubic-bezier(0.22, 0.61, 0.36, 1), opacity .2s linear, height .7s;
    mix-blend-mode: difference;
    background: transparent !important;
    height: 0;
  }

  video {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .img-only {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .blink_me {
    animation: blinker 2s linear infinite;
  }

  @keyframes blinker {
    70% {
      opacity: 0;
    }
  }

  .content {
    width: 100%;
    font-size: 20px;
    position: absolute;
    z-index: inherit;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media screen and (min-width: 768px) {
      width: 85%;
    }
    @media screen and (min-width: 1024px) {
      width: 65%;
      max-width: 650px;
    }
    @media screen and (min-width: 1200px) {
      width: 70%;
      max-width: 850px;

      h1 {
        font-size: 35px;
        font-style: bold;
      }
    }
    @media screen and (min-width: 1600px) {
      width: 50%;
      max-width: 900px;
    }

    h1 {
      margin-bottom: 0px;
    }

    .logo {
      float: right;
      span {
        padding-left: 5px;
        font-size: 25px;
      }
      img {
        height: 35px;
        @media screen and (min-width: 768px){
          height: 25px;
        }
      }
    }

    .bottom {
      padding-top: 0px;
      .left {
        p {
          margin-bottom: 0;
        }
      }
      .right {
        line-height: 0;
      }
    }
  }
}
