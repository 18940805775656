@import '../base/variables';

// Styles to help the logo animation.
#body {
  width: 100%;
  &.fixed {
    max-height: 100vh;
    height: 100vh;
    background: #fff;
    overflow: hidden;
  }
  #home {
    position: relative;
    z-index: 15;
  }
  &.logoLoaded {
    #home {
      z-index: unset;
      #header {
        height: auto;
        margin-bottom: 10px;
        padding-bottom: 40px;
        transform: translate3d(0,0,0);
      }
    }
  }
}

#home, #homePageModal {
  color: $soft-gray;

  .modal-content {
    .header {
      min-height: 60px;
    }
  }

  .dot {
    line-height: 0;
    font-size: 5px;
    margin: 0 10px;
  }

  .title-wrapper {
    //overflow: hidden;
    font-size: 20px;
    font-family: 'Amare Alta';
    flex-wrap: wrap;
    .title { // , .creators
      flex: auto;
      max-width: 90%;
    }
    .dotwrap {
      flex: 0;
    }
    span.ellipsis {
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 100%;
      overflow: hidden;
      display: inline-block;
    }
  }
}

#home {
  min-height: 100vh;

  a {
    color: #fff;
  }

  .loginButton.btn-link {
    transition: color 0.5s;
    span {
      margin-right: 10px;
    }
    &:hover {
      color: $dark-green
    }
  }

  #main {
    overflow: hidden;
    margin-top: 25px;
    //padding-bottom: 100px;
  }

  .highlights {
    .audioPreview {
      height: 80px;
      max-height: 80px;
      position: relative;
      overflow: hidden;
    }
    .pdf {
      height: 85vh !important;
    }
    picture:nth-child(-n+2) {
      height: auto;
    }
    picture:nth-child(-n+3) {
      height: 350px;
    }

    .middle {
      line-height: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 50px;
      transform: translate(-50%, -50%);

      .collection_icon {
        width: 70px;
        height: 70px;
      }

      .collections_in_collection_icon {
        width: 70px;
      }
    }
  }

  #header {
    height: 0;
    background: $dark-blue;
    transform: translate3d(0,-60vw,0);
    transition: transform .5s cubic-bezier(0.22, 0.61, 0.36, 1);

    .file {
      cursor: pointer;
      position: relative;
    }

    .middle {
      line-height: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 50px;
      transform: translate(-50%, -50%);
    }
  }

  .announcements {
    h3 {
      color: #000;
    }
    .announcement {
      color: #000;

      .title {
        font-size: 18px;
        @media screen and (min-width: 768px) {
          font-size: 20px;
        }
      }

      .description {
        font-size: 16px;
        @media screen and (min-width: 768px) {
          font-size: 18px;
        }
      }

      .type {
        font-size: 13px;
      }

      a {
        color: #000;

        svg {
          margin-left: 20px;
        }
      }
    }
  }

}

#homePageModal {
  &.fullwidth {
    max-width: 98vw;
    overflow: hidden;
  }

  .audioPreview {
    height: 80px;
  }

  .modal-content {
    background-color: $dark-blue;
    border: none;
    border-radius: 0;
    overflow: hidden;

    height: calc(100vh - (0.5rem * 2));
    @media (min-width: 576px) {
      height: calc(100vh - (1.75rem * 2));
    }

    .header {
      background: $dark-blue;
      padding: 15px 15px;

      a.gray {
        color: $soft-gray;
        font-family: 'Minion Pro', sans-serif;
        font-size: 14px;
        opacity: .5;
        &.openButton {
          line-height: 1.2;
          display: inline-block;
          margin-right: 10px;
          font-size: 17px;
        }

        &:hover {
          opacity: 1;
        }
      }

      .closeButton, .openButton {
        cursor: pointer;
      }
    }

    .info {
      overflow-y: scroll;
      height: 100%;
      overflow-x: hidden;
      padding: 0 15px;

      .file {
        flex: 1 0;
        overflow: hidden;
        .background {
          filter: blur(6px);
          z-index: -1;
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
        }

        .video {
          z-index: 0;
          position: relative;
          .player {
            width: auto !important;
            height: 100% !important;
          }
        }
        .image {
          z-index: 0;
          height: 100%;
          img {
            max-height: 100%;
            z-index: 1;
          }
        }
      }

    }

    .body {
      padding: 15px 15px;
      font-family: 'Minion Pro', sans-serif;
      font-size: 18px;

      .tags {
        color: $soft-blue;
      }
    }
  }
}

.title {
  font-family: 'Amare Alta', sans-serif;
}

.creators {
  font-family: 'Amare Alta', sans-serif;
}