@keyframes animation_spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes animation-slide-fade-in {
  from {
    opacity: 0;
    transform: translate3d(0, 50px, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

.slide-fade-in {
  animation: animation-slide-fade-in 600ms cubic-bezier(0.08, 0.8, 0.3, 1) forwards;
}


/*
  Marquee
 */
@-moz-keyframes marquee {
  0%   { -moz-transform: translateX(100vw); }
  100% { -moz-transform: translateX(-100%); }
}
@-webkit-keyframes marquee {
  0%   { -webkit-transform: translateX(100vw); }
  100% { -webkit-transform: translateX(-100%); }
}
@keyframes marquee {
  0%   {
    -moz-transform: translateX(100vw); /* Firefox bug fix */
    -webkit-transform: translateX(100vw); /* Firefox bug fix */
    transform: translateX(100vw);
  }
  100% {
    -moz-transform: translateX(-100%); /* Firefox bug fix */
    -webkit-transform: translateX(-100%); /* Firefox bug fix */
    transform: translateX(-100%);
  }
}
