@import '../../base/variables';

#item {

  // color: $soft-gray;
  // background-color: $dark-blue;

  h1 {
    font-size: 40px;
  }

  .file {
    flex: 1 0;
    overflow: hidden;
    .background {
      filter: blur(6px);
      z-index: -1;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
    }

    .hrefBlock {
      width: 100%;
      height: 100%;
      display: block;
    }

    .pdf {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      height: 50vh;
      @media (min-width: 540px) {
        height: 50vw;
      }
      @media (min-width: 760px) {
        height: 50vh;
      }
    }

    .video {
      z-index: 0;
      position: relative;
      .player {
        width: 100% !important;
        height: auto !important;
        display: flex;
      }
    }
    .image {
      // max-height: 50vh;
      // @media (min-width: 1200px) {
      //   max-height: 70vh;
      // }
      // @media (max-height: 768px) and (orientation:landscape) {
      //   max-height: 70vh;
      // }

      z-index: 0;
      img {
        height: auto;
        max-height: 100%;
        z-index: 1;
      }
    }
  }

  .subline {
    color: $subline-gray;
    font-size: 18px;
  }

  .left, .right {
    padding: 15px 15px;
    //@media
  }

  .left {
    border-color: $item-details-border !important;

    .creators {
      font-size: 16px;
      font-family: 'Amare Alta';
    }
    .description {
      white-space: pre-wrap;
      word-break: break-word;
      overflow-y: scroll;
      max-height: 55vh;
      font-size: 18px;
    }
    .description::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px $dark-blue;
      border-radius: 5px;
      background-color: $dark-blue
    }
    .description::-webkit-scrollbar {
      width: 6px;
      background-color: $dark-blue;
      border-radius: 10px;
    }
    .description::-webkit-scrollbar-thumb {
      border-radius: 5px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
      background-color: $mid-gray;
    }
  }

  .right {
    @media screen and (max-width: 540px) {
      margin-top: 15px;
      border-top: 1px solid $item-details-border;
    }
    .details {
      border-color: $item-details-border !important;
      padding: 15px;
      word-break: break-word;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
      }
    }
  }


  // NOTE this is the same height as .detailPreview picture image
  .carousel {
    .carousel-indicators {
      margin-bottom: -1rem;
    }
    .carousel-control-next, .carousel-control-prev {
      cursor: pointer;
      width: 50px;
      height: 50px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .freq_wave-iframe {
    width: 100%;
    height: 82vh;
    border: none;
  }

  .soundcloud-iframe {
    width: 100%;
    height: 300px;
  }

  .threedee_model-iframe {
    width: 100%;
    height: 70vh;
    border: none;
    overflow: hidden;
  }

  .special_item-iframe {
    width: 100%;
    height: calc(100vh - 3.75rem);
    border: none;
    overflow: hidden;

    margin-bottom: -10px;

    @media screen and (min-width: 992px) {
      margin-bottom: -125px;
    }
    @media screen and (min-width: 1500px) {
      margin-bottom: -155px;
    }
  }
}
