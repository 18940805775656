@import '../../base/variables';

.embed-header {
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  
}

#embed_title {
  display:inline-block;
  float:left;
  position: absolute;
  height:30px;
  z-index:999;
  width:50%;
  top: 30px;   
  overflow: hidden;    
  text-overflow: ellipsis;
  white-space: nowrap;
}

#embed_audio_title {
  display:inline-block;
  float:left;
  color: #000;
  position: absolute;
  height:30px;
  z-index:999;
  width:50%;
  top: 30px;   
  overflow: hidden;    
  text-overflow: ellipsis;
  white-space: nowrap;
}

#embed_logo {
  /*background-color: $dark-blue;*/
  display:block;
  color: $soft-gray;
  width: auto;
  height: 40px;
  width: 148px;
  position:absolute;
  z-index:999;
  top: -4px;
  left: 11px;
}

#open_link {
  display:block;
  float:right;
  color: $soft-gray;
  position: absolute;
  top: rem(5px);
  right: rem(10px);
  z-index:999;
}

#embed_item {
  color: $soft-gray;
  background-color: $dark-blue;
  height: 100vh !important;

  h1 {
    font-size: 40px;
  }

  .file {
    flex: 1 0;
    overflow: hidden;
    .background {
      filter: blur(6px);
      z-index: -1;
      position: relative;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
    }

    .pdf {
      height: 100vh;
    }

    .video {
      z-index: 0;
      position: relative;
      .player {
        width: auto !important;
        height: auto !important;
        display: flex;
      }
    }
    .image {
      max-height: 100vh;
      @media (min-width: 1200px) {
        max-height: 70vh;
      }
      @media (max-height: 768px) and (orientation:landscape) {
        max-height: 70vh;
      }

      z-index: 0;
      img {
        height: 100%;
        max-height: 100%;
        z-index: 1;
      }
    }
  }

  .subline {
    color: $subline-gray;
    font-size: 18px;
  }

  .left, .right {
    padding: 15px 15px;
    //@media
  }

  .left {
    border-color: $item-details-border !important;

    .creators {
      font-family: 'Amare Alta';
      font-size: 16px
    }
    .description {
      white-space: pre-wrap;
      word-break: break-word;
      overflow-y: scroll;
      max-height: 55vh;
      font-size: 18px;
    }
    .description::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px $dark-blue;
      border-radius: 5px;
      background-color: $dark-blue
    }
    .description::-webkit-scrollbar {
      width: 6px;
      background-color: $dark-blue;
      border-radius: 10px;
    }
    .description::-webkit-scrollbar-thumb {
      border-radius: 5px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
      background-color: $mid-gray;
    }
  }

  .right {
    @media screen and (max-width: 540px) {
      margin-top: 15px;
      border-top: 1px solid $item-details-border;
    }
    .details {
      border-color: $item-details-border !important;
      padding: 15px;
      word-break: break-word;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
      }
    }
  }


  // NOTE this is the same height as .detailPreview picture image
  .carousel {
    .carousel-indicators {
      margin-bottom: -1rem;
    }
    .carousel-control-next, .carousel-control-prev {
      cursor: pointer;
      width: 50px;
      height: 50px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

body::-webkit-scrollbar {
  display: none;
}