@import '../../../styles/base/variables';
@import '../../../styles/base/mixins';

.portfolio-header {
  width: 100%;
  max-width: rem(320px);
  background-color: $dark-blue;
  padding: calc(var(--header-height) + 100px) rem(24px) rem(60px);
  text-align: center;
  color: white;
  position: relative;

  @include media-breakpoint-down(md) {
    display: flex;
    max-width: 100%;
    padding-bottom: rem(80px);
    padding: calc(var(--header-height) + 60px) rem(24px) rem(60px);

    &__picture, &__details {
      flex: 1;
    }
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }

  &__picture {
    position: relative;
    padding: 0 rem(18px);
  }

  &__details {
    margin-top: rem(10px);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__edit {
    font-family: Amare Alta, sans-serif;
    margin-top: rem(10px);

    span { text-decoration: underline }
    svg { margin-bottom: rem(2px) }

    &:hover {
      cursor: pointer;
      span { text-decoration: none }
    }
  }

  &__name {
    font-size: rem(22.5px);
    font-family: Amare Medium, sans-serif;
    text-transform: uppercase;
    margin-bottom: rem(10px);
  }

  &__subheadline {
    font-family: Amare Alta, sans-serif;
    margin-bottom: rem(20px);
  }

  &__data {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: rem(5px);

    > svg {
      margin: 0 rem(5px) rem(5px) 0;
    }
  }

  &__tags {
    font-family: 'Minion Pro';
    font-size: rem(18px);
    list-style: none;
    margin-top: rem(45px);
    padding: 0;

    li {
      margin-top: rem(6px);
    }

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &__nav {
    display: flex;
    flex-direction: column;
    gap: rem(25px);
    padding: 0 rem(55px);
    margin-top: rem(45px);

    // @include media-breakpoint-down(md) {
    //   position: absolute;
    //   bottom: rem(-90px);
    //   left: 0;
    //   width: 100%;
    //   flex-direction: row;
    //   justify-content: center;
    //   color: $dark-blue;

    //   .button {
    //     border-color: $dark-blue !important;
    //   }
    // }

    // @include media-breakpoint-down(sm) {
    //   gap: rem(15px);
    // }
  }

  &__socials {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: rem(45px);
    gap: rem(18px);

    a {
      color: white;
    }

    svg {
      width: auto;
      height: rem(25px);
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: rem(25px);
    margin-top: rem(45px);

    .button {
      margin-top: rem(5px);
    }

    @include media-breakpoint-down(md) {
      flex-direction: row;
      justify-content: center;

      .caption {
        display: none;
      }
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }
}
