@import 'variables';

#body {
  font-family: 'Minion Pro';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;

  &.theme-dark {
    background-color: $dark-blue;
  }

  &:has(.newsflash) {
    padding-top: $newsflash-height;
  }

  #content {
    padding-bottom: 100px
  }
}

button.page-link.tag {
  padding: 3px 5px 1px !important;
  border: 1px solid;
  border-color: inherit;
  margin-right: 10px;
  color: inherit;
  background: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  transition: all 200ms ease;

  &:hover, &.active {
    color: #000 !important;
    background-image: linear-gradient(85.78deg, #A7D0FF -10%, #C2F5E7 42.02%, #d7b8fa 110%);
    border-image: linear-gradient(85.78deg, #A7D0FF -10%, #C2F5E7 42.02%, #d7b8fa 110%);
  }
}

a, .btn-link {
  color: $dark-green;
  transition: color 200ms ease;
  text-decoration: none;
  &:hover, &:focus {
    text-decoration: none;
    color: #9746de;
  }

}

a.tba21logolink {
  color: $subline-gray;
  &:hover {
    color: $soft-gray;
  }
}

.main, #main {
  min-height: 100vh;
  background-color: $dark-blue;

  &.pb {
    @media screen and (min-width: 992px) {
      padding-bottom: 115px; // offset the search and about bars
    }
    @media screen and (min-width: 1500px) {
      padding-bottom: 145px; // offset the search and about bars
    }
  }
  &.blue {
    background-color: $dark-blue;

    &.container-fluid {
      padding: 0;
    }
  }

  &.editor, &.admin {
    color: $soft-gray;
    padding-top: calc($header-height);
    // background-color: white;
    .table {
      color: $soft-gray;
    }
  }
}

img {
  max-width: 100%;
  height: auto;
}

iframe {
  border: none;
}

.tba21logo {
  height: 23px;
  left: 0;
  right: auto;
}


.overlay_fixed_middle {
  z-index: 9999;
  position: fixed;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.8);

  .middle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .spinner-grow {
      color: #fff;
      height: 90px;
      width: 90px;
    }
  }
}

.modal-backdrop.show {
  background-color: #0d2c4f;
  opacity: 0.4;
}
