
form {
  font-family: Amare Medium, sans-serif;

  &.fullscreen-lines {
    input {
      &.form-control{
        background-color: $soft-gray;
      }
    }
    .form-group {
      border: none;
      margin-bottom: 0;

      // Top and bottom full width lines
      &:after, &:before {
        content: '';
        display: block;
        width: 200vw;
        border-bottom: 1px solid $mid-gray;
        position: absolute;
        left: -100vw;
      }
    }
  }

  input, textarea {
    &.form-control {
      font-size: 20px;
      width: 100%;
      height: rem(43px);
      padding: rem(2px) rem(12px) 0;
      border-radius: 0;
      background-color: transparent;
      appearance: none;
      font-family: Amare Alta, sans-serif;

      .theme-dark & {
        color: $soft-gray;
        border: 1px solid $soft-gray;
      }

      &:focus {
        outline: none;
        border-color: $dark-gray;
        background-color: transparent;
        box-shadow: none;

        .theme-dark & {
          color: $soft-gray;
          border-color: $soft-gray;
        }
      }

      &:disabled {
        background-color: initial;
        opacity: 0.5;
      }

      &--large {
        height: rem(73px);
        border-radius: rem(24px);
        text-align: center;

        &::placeholder {
          text-align: center;
          font-family: Amare Medium, sans-serif;

          .theme-dark & {
            color: $soft-gray;
          }
        }
      }

      .os-win & {
        padding: 0 rem(12px) rem(4px);
      }
    }
  }

  textarea.form-control {
    font-size: rem(16px);
    padding-top: rem(8px);
  }

  label {
    font-size: 1.2em;
    font-family: Amare Medium, sans-serif;
    position: relative;
  }

  // label:has(+ .form-control[required])::after {
  //   content: "*";
  // }
  

  .select {
    .select__control {
      background-color: transparent;
      border-radius: 0;
      * {
        font-family: Amare Medium, sans-serif;

        .theme-dark & {
          color: $soft-gray;
        }
      }
      .select__value-container {
        padding: 10px 15px;
        font-family: 'Minion Pro', sans-serif;
        font-size: 18px;
      }
    }
    .select__menu {
      color: $dark-blue;
      border-radius: 0;
      margin: 0;
      font-family: Amare Medium, sans-serif;
      z-index: 10;
    }
    .select__multi-value {
      .theme-dark & {
        background-color: #eeeeee40 !important;
      }
      &__label {
        padding-top: 4px;
        padding-bottom: 1px;
      }
    }
    .select__multi-value__remove {
      background-color: transparent !important;
    }
  }

  .license_type {
    * {
      color: $soft-gray;
      font-family: Amare Medium, sans-serif;
    }
    div[class*="menu"] * {
      color: $dark-blue;
    }
  }

  div[class^="EditorToolbar"] {
    .theme-dark & {
      color: #000;
      background-color: #fff;
      margin: 0;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  div[class^="RichTextEditor__root"] {
    border-radius: 0;
    background-color: transparent;
  }
  div[class^="RichTextEditor__editor"] {
    min-height: 100px;
    font-family: Amare Medium, sans-serif;
  }
}

.oa-checkbox {
  display: inline-flex;
  align-items: flex-start;
  line-height: calc(50% + 13px);

  input[type="checkbox"] {
    width: rem(20px);
    height: rem(20px);
    border: 1px solid;
    border-radius: 0;
    background-color: transparent;
    appearance: none;
    position: relative;
    cursor: pointer;

    &::after {
      content: "";
      display: block;
      width: rem(12px);
      height: rem(12px);
      top: rem(3px);
      left: rem(3px);
      position: absolute;
      background-color: currentColor;
      transform: scale(0.85);
      opacity: 0;
      transition: all 150ms ease;
    }

    &:checked {
      &::after {
        opacity: 1;
        transform: none;
      }
    }

    + span {
      margin-left: rem(10px);
      user-select: none;
      cursor: pointer;
    }
  }
}

.oa-dropdown {
  input[type="text"] {
    font-family: Amare Alta, sans-serif;
    border: none;
    border-radius: 0;
    padding: rem(3px) rem(30px) 0 rem(10px);
    width: 100%;
    height: rem(32px);
    color: #000;
    overflow: hidden;
    text-overflow: ellipsis;

    &:focus {
      box-shadow: none;
    }

    .os-win & {
      padding: 0 rem(30px) rem(3px) rem(10px);
    }
  }

  .dropdown-toggle {
    &::after {
      top: calc(50% - 3px);
      right: 10px;
      position: absolute;
      border-top: 7.5px solid #000;
      border-right: 6.5px solid transparent;
      border-bottom: 0;
      border-left: 6.5px solid transparent;
      pointer-events: none;
    }
  }

  .dropdown-clear {
    top: 50%;
    right: 6.5px;
    position: absolute;
    transform: translateY(-50%);
    color: #000;

    &:hover {
      opacity: 0.7;
    }
  }

  .dropdown-menu {
    width: 100%;
    border: none;
    border-radius: 0;
    max-height: 280px;
    overflow-y: auto;

    &[x-placement="top-start"] {
      top: auto !important;
      bottom: 0 !important;
      transform: translate(0, -34px) !important;
    }
    &[x-placement="bottom-start"] {
      transform: translate(0, rem(32px)) !important;
    }

    &:empty::after {
      content: "No matching items";
      font-family: Amare, sans-serif;
      padding: 3px 10px 0;
    }
  }

  .dropdown-item {
    display: flex;
    padding-top: 7px;
    overflow: hidden;
    align-items: center;
    justify-content: space-between;
    font-family: Amare Alta, sans-serif;

    &-label {
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-info {
      margin-left: 10px;
      text-align: right;
      font-size: 0.8em;
      opacity: 0.5;
    }
  }
}

.oa-radio {
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 999px;
  border: 3px solid white;
  margin: 0 10px 0 0;
  background-color: white;
  cursor: pointer;

  &:checked {
    background-color: transparent;
  }

  + label {
    margin: 0;
    top: -2px;
    position: relative;
    cursor: pointer;
  }
}

.oa-input {
  color: #fff;
  font-size: 20px;
  width: 100%;
  height: rem(43px);
  padding: rem(2px) rem(12px) 0;
  border: 1px solid #fff;
  background-color: transparent;
  appearance: none;
  font-family: Amare Alta, sans-serif;

  &:focus {
    outline: 1px solid #fff;
  }

  .os-win & {
    padding: 0 rem(12px) rem(4px);
  }
}
