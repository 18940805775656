$slider-width: 100px;
$slider-height: 25px;

$slider-label-height: 40px;
$slider-label-width: 50px;

$slider-edge-box-width: 60px;
$slider-edge-box-bg: #d0d0d0;
$slider-edge-box-color: #000;

$slider-bg: rgba(0,49,113,1);
$slider-label-bg: #fff;
$slider-label-border: 6px solid $slider-bg;

.slider-wrapper {
  transform: rotate(90deg);
  width: $slider-width;
  margin: ($slider-label-height + 20) auto;
}
.fluid-slider {
  -webkit-appearance: none;
  width: $slider-width;
  height: $slider-height;
  background: linear-gradient(90deg, rgba(213,227,255,1) 0%, rgba(0,49,113,1) 100%);
  border-radius: 2px;
  margin: 0;
  position: relative;
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
    background: #fff;
    opacity: 0.65;
    border: 4px solid $slider-bg;
    position: relative;
    transition: all ease-in-out 0.05s;
  }
  &::-moz-range-thumb {
    -moz-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    cursor: pointer;
    background: #fff;
    opacity: 0.65;
    border: 4px solid $slider-bg;
    position: relative;
    transition: all ease-in-out 0.05s;
  }
  &:focus {
    outline: none;
  }
  &:active {
    &::-webkit-slider-thumb {
      background: transparent;
    }
    &::-moz-range-thumb {
      background: transparent;
    }
    + .range-label {
      visibility: visible;
      opacity: 1;
      top: - ($slider-height + $slider-label-height + 10);
    }
  }
  &:before, &::after {
    position: absolute;
    content: '';
    width: $slider-edge-box-width;
    padding: 0.5em;
    background: $slider-edge-box-bg;
    top: 50%;
    transform: translateY(-50%) rotate(-90deg);
    box-sizing: border-box;
    text-align: center;
    font-weight: bold;
    border-radius: 2px;
  }
  &:before {
    left: - ($slider-edge-box-width);
    content: attr(min);
  }
  &:after {
    right: - ($slider-edge-box-width);
    content: attr(max);
  }
}

.range-label {
  position: relative;
  display: block;
  width: 40px;
  height: 40px;
  top: - ($slider-height + $slider-label-height);
  visibility: hidden;
  opacity: 0;
  background: $slider-label-bg;
  border-radius: 50%;
  line-height: 30px;
  text-align: center;
  font-weight: bold;
  font-size: 0.8em;
  transform: translateX(-50%);
  box-sizing: border-box;
  border: $slider-label-border;
  left: attr(value) + 20;
  transition: all ease-in .05s;
  z-index: -1;
  &:after {
    transform: rotate(90deg);
    position: absolute;
    content: '';
    top: 33px;
    left: 50%;
    width: 10px;
    height: 8px;
    background-repeat: no-repeat;
    background-image: radial-gradient(circle, white 50%, transparent 50%), radial-gradient(circle, white 50%, transparent 50%), linear-gradient($slider-bg, $slider-bg ) ;
    background-size:  180% 100%,180% 100%, 100% 100%;
    background-position: 150% 0%, -50% 0%, 100% 100%;
  }
}
::-moz-range-track {
  background: $slider-bg;
  border: 0;
}
input::-moz-focus-inner,
input::-moz-focus-outer {
  border: 0;
}
