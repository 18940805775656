@import '../../styles/base/variables.scss';
@import '../../styles/base/mixins.scss';

.search-results {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  padding: 0 rem(48px) rem(32px);
  flex: 1 1 auto;

  @include media-breakpoint-down(md) {
    padding: 0 var(--page-margin);
  }

  @include media-breakpoint-up(lg) {
    padding-left: rem(20px);
  }

  > p {
    @include subheadlineFont;
  }
}