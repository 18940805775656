
@font-face {
  font-family: 'Minion Pro';
  font-style: bold;
  font-weight: normal;
  font-display: swap;
  src: local('Minion Pro Bold'),
       url('./minion-pro/MinionPro-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Minion Pro';
  font-style: italic;
  font-weight: normal;
  font-display: swap;
  src: local('Minion Pro Italic'),
       url('./minion-pro/MinionPro-It.woff2') format('woff2');
}

@font-face {
  font-family: 'Minion Pro';
  font-style: regular;
  font-weight: normal;
  font-display: swap;
  src: local('Minion Pro'),
       local('Minion Pro Regular'),
       url('./minion-pro/MinionPro-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Amare Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Amare'), url('./amare/Amare-Linnea.woff2') format('woff2');
}

@font-face {
  font-family: 'Amare Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Amare Medium'), url('./amare/Amare-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Amare Alta';
  font-style: normal;
  font-weight: normal;
  src: local('Amare Alta'), url('./amare/Amare-Alta.woff2') format('woff2');
}