@import '../../../styles/base/variables';
@import '../../../styles/base/mixins';

.profile-page {
  &__intro {
    color: white;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: rem(20px) var(--page-margin) rem(70px);

    @include media-breakpoint-down(sm) {
      display: block;
    }

    &-picture {
      flex: 1 0 50%;
      display: flex;
      justify-content: flex-end;

      @include media-breakpoint-down(md) {
        flex-basis: 30%;
      }
      @include media-breakpoint-down(sm) {
        justify-content: center;
        margin-bottom: rem(40px);
      }

      img {
        width: rem(333px);
        height: rem(333px);
        border-radius: 999px;
        margin-right: rem(60px);
        object-fit: cover;

        @include media-breakpoint-down(md) {
          width: rem(260px);
          height: rem(260px);
          margin-right: rem(40px);
        }
        @include media-breakpoint-down(sm) {
          width: rem(220px);
          height: rem(220px);
          margin-right: 0;
        }
      }
    }

    &-details {
      flex: 1 0 50%;
      @include sansBodyCompactFont;
      line-height: 141%;
    }
  }

  &__name {
    @include headlineFont;
    margin-bottom: rem(5px);
  }

  &__data {
    @include sansBodyCompactFontLight;
    margin-bottom: rem(10px);
  }

  &__contributions {
    padding: rem(50px) rem(25px);
    h2 {
      @include sansBodyCompactFont;
      font-size: rem(26px);
    }
  }

  &__items {
    padding: 0 rem(10px);
    // display: flex;
    // flex-wrap: wrap;
    // column-gap: rem(10px);
    // margin-top: rem(25px);

    // > div {
    //   flex: 1 0 24%;
    // }
  }

  .alert {
    margin-top: rem(80px);
  }
}
