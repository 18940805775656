@import '../base/variables.scss';

.dropzone_wrapper {
  border: 1px solid $soft-gray;
  border-radius: 56px;
  padding: 0 !important;

  .dropzone {
    padding: 80px 0;
    text-align: center;
    border-radius: 56px;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &:focus {
      outline: -webkit-focus-ring-color auto 0;
    }
    &.active {
      background-color: #ffffff20;
    }
  }

  div {
    cursor: pointer;
  }

  ul {
    &.previewItems {
      padding: 0;

      li {
        list-style: none;
        display: inline-block;
        width: 20%;
        min-width: 165px;
        vertical-align: top;
        margin: .7em;

        img {
          width: 100%;
        }
      }
    }
  }
}
