@import '../../styles/base/variables.scss';
@import '../../styles/base/mixins.scss';

.item-simple {
  display: flex;

  &__preview {
    width: 70%;
    position: relative;

    &::before {
      content: "";
      padding-top: 75%;
      display: block;
      @include loadingShimmer;
    }

    img {
      object-fit: cover;
      @include absoluteCover;
    }

    svg {
      right: rem(15px);
      bottom: rem(10px);
      width: rem(34px);
      height: rem(34px);
      position: absolute;
      color: white;
      transition: color 200ms ease;
    }

    &:hover svg {
      color: $focus_scitech;
    }
  }

  &__body {
    flex: 1;
    padding-left: rem(28px);
  }

  &__title {
    margin: 0 0 10px;
    font-size: 20px;

    a {
      color: inherit;
    }
    a:hover {
      color: $focus_scitech !important;
    }
  }

  &__meta {
    @include subheadlineFont;
    font-size: 16px;
  }

  &__description {
    margin: 10px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    -webkit-box-orient: vertical;
    @include sansBodyCompactFont;
  }

  mark {
    padding: 0.15em 0 0;
    background-color: $soft-violet;
  }
}