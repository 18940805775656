@import '../base/variables';
@import '../base/mixins';

.info-page {
  color: white;
  padding: rem(60px) var(--page-margin) rem(80px);

  &__content {
    width: 100%;
    max-width: rem(568px);
    margin: 0 auto;

    h1 {
      @include headlineFont;
    }
    h2 {
      @include subheadlineFont;
      font-size: rem(30px);
    }

    h3 {
      @include subheadlineFont;
      font-size: rem(20px);
      text-transform: uppercase;
    }
  }
}
