@import '../base/variables.scss';

.draggable {
  &-item {
    background-color: $mid-gray;
    transition: background-color 200ms ease;
    &:hover {
      background-color: white !important;
    }
  }
}