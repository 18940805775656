@import "../../base/variables";


.help-stories-modal {

  font-family: 'Amare Medium';

  h2 {
    color: inherit;
    background-color: inherit;
  }

}

.help-stories-modal-body {
  background-image: linear-gradient(
    153.16deg,
    rgba(80, 226, 193, 0.26) 5.91%,
    rgba(144, 57, 254, 0.26) 52.99%,
    rgba(0, 118, 255, 0.26) 104.16%
  ),
  linear-gradient(white, white) !important;
  overflow-x: hidden;
  min-height: calc(100vh - 200px);
  padding-top: none;
  height: calc(100vh - 200px);
  font-family: 'Minion Pro';
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Amare Medium'
  }
}


.help-stories-modal-header {
  background-image: linear-gradient(
    153.16deg,
    #0076FF 5.91%,
    #9013FE 52.99%,
    #50E3C2  104.16%
  ),
  linear-gradient(white, white) !important;
  color: $soft-gray;
  font-family: 'Amare Alta';
  h5 {
    font-size: 34px !important;
  }
}
