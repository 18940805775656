@import '../../styles/base/variables.scss';
@import '../../styles/base/mixins.scss';

.view-item {
  &__body {
    display: flex;
    flex-wrap: wrap;
    padding: rem(30px) var(--page-margin) 0;

    @media (max-width: 1140px) {
      display: block;
      padding-bottom: rem(80px);
    }
  }
  &.embedded &__body {
    padding: rem(15px) rem(15px) rem(5px);
    height: 100vh;

    #embed_logo {
      left: auto;
      top: -12px;
      position: relative;
    }

    h2 {
      @media (max-width: 575px) {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-top: -10px;
      }
    }
  }

  &__subtype {
    width: 100%;
    text-transform: uppercase;
    margin-bottom: rem(40px);
    opacity: 1;

    @media (max-width: 767px) {
      margin-bottom: rem(20px);
    }
  }

  &__title {
    @include headlineFont;

    @media (max-width: 767px) {
      font-size: rem(30px) !important;
    }
  }

  h2 {
    @include headlineFont;
    text-transform: uppercase;
    font-size: rem(30px);
  }

  &__left {
    flex: 0 1 25%;
    max-width: rem(400px);
    @include sansBodyCompactFontLight;

    > div {
      position: sticky;
      top: calc($header-height + 40px);

      .modal & {
        position: static;
      }
    }

    p {
      margin: 10px 0;
    }
  }

  &__meta {
    width: 100%;
    @include sansBodyCompactFontLight;

    @media (max-width: 1140px) {
      margin-top: rem(40px);
    }

    p {
      margin: 10px 0;
    }

    .tag {
      font-size: 14px;
    }
  }

  &__concept-tags .tag {
    margin-bottom: rem(8px);
  }
  &__keyword-tags .tag {
    color: inherit;
    padding: 0 rem(5px) 0 0 !important;
    background-color: transparent !important;
    // margin-bottom: rem(8px);
  }

  &__right {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding-left: rem(80px);

    @media (max-width: 1140px) {
      padding-left: 0;
      flex-direction: column;
    }
  }
  &.embedded &__right {
    padding: 0;
    height: 100%;
    flex-wrap: nowrap;
    align-items: normal;
    flex-direction: column;
  }

  &__preview {
    width: 100%;
    margin-bottom: rem(30px);
    position: relative;

    .pdf canvas {
      width: 100% !important;
      height: auto !important;
    }
  }

  &__actions {
    &-first-row {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      column-gap: rem(20px);
      // flex: 1;

      > div {
        display: flex;
      }
    }

    &-favourite svg {
      width: rem(30px);
      height: rem(30px);
      margin-right: rem(-4px);
    }
    &-share svg {
      width: rem(22px);
      height: rem(22px);
    }
    &-embed svg {
      width: rem(26px);
      height: rem(26px);
    }
    &-citation svg {
      // color: $focus_arts;
    }

    .theme-switch {
      .modal & {
        display: none;
      }
    }
  }

  &__description {
    flex: 1;
    padding-right: rem(20px);
    margin-bottom: rem(20px);
    overflow: hidden;
    position: relative;

    &:not(.open) {
      max-height: rem(460px);
    }

    @media (max-width: 767px) {
      width: 100%;
    }

    > div {
      width: 80%;
      max-width: rem(880px);

      @media (max-width: 1279px) {
        width: 100%;
      }
      @media (max-width: 767px) {
        min-width: auto;
      }
    }
  }

  &__description-text {
    &.overflown {
      mask-image: linear-gradient(#000 55%, transparent 83%);
      mask-repeat: no-repeat;
      mask-size: 100% 460px;

      .open & {
        mask: none;
      }
    }
  }

  &__description-more {
    bottom: 0;
    position: absolute;
  }

  &__download {
    height: rem(30px);
    margin-bottom: rem(18px);

    a {
      display: flex;
      align-items: center;
      @include sansBodyCompactFont;
    }

    svg {
      width: rem(30px);
      height: rem(30px);
      margin-right: rem(10px);
    }

    span {
      padding-top: rem(4px);
    }
  }

  &__subitems, &__subcollections {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
  }

  &__subitem {
    &-title {
      margin: 5px 0 10px;
      font-size: 20px;

      a {
        color: inherit;
      }
      a:hover {
        color: $focus_scitech !important;
      }
    }
    &-meta {
      @include subheadlineFont;
      font-size: 16px;
    }
    &-description {
      margin: 10px 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      line-clamp: 4;
      -webkit-box-orient: vertical;
      @include sansBodyCompactFont;
    }
  }

  &__arrows {
    @include absoluteCover;
    display: flex;
    align-items: center;
    padding: 0 rem(20px);
    transition: opacity 200ms ease;
    pointer-events: none;

    @media (min-width: 768px) {
      opacity: 0;
      pointer-events: none;
    }

    @media (max-width: 767px) {
      position: static;
      justify-content: space-between;
      padding: 0;
    }

    .button {
      pointer-events: all;
      position: absolute;
      left: rem(20px);
      pointer-events: all;
      min-width: 0;
      
      @media (max-width: 767px) {
        position: static;
        margin-top: rem(20px);
        margin-right: auto;
        min-width: rem(152px);
      }

      span:last-child {
        left: 0;
        line-height: 1.2;
        font-size: rem(20px);
        
        @media (min-width: 768px) {
          top: rem(65px);
          opacity: 0;
          text-align: left;
          position: absolute;
          transform: translateX(-10px);
          transition: all 200ms ease;
          pointer-events: none;
          color: $white;
        }

        @media (max-width: 767px) {
          margin: 0 rem(5px);
        }
      }

      &.arr-right {
        left: auto;
        right: rem(20px);
        
        @media (max-width: 767px) {
          margin-left: auto;
          margin-right: 0;
          flex-direction: row-reverse;
        }

        span:last-child {
          @media (min-width: 768px) {
            right: 0;
            left: auto;
            text-align: right;
            width: fit-content;
            transform: translateX(10px);
          }
        }
      }

      &:hover {
        span:last-child {
          transform: none;
          opacity: 1;
        }
      }
    }
  }
    &__preview:hover &__arrows {
      opacity: 1;
    }
}