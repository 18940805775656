@import "../../base/variables";

.mapWrapper {
  flex: 100% 1;
  min-height: 360px;
  height: calc(100vh);
  position: relative;
  z-index: 1;
  padding-top: $header-height;

  // Legend styling
  .legend span, .legend label {
    display: block;
    width: 50px;
    height: 18px;
    float: left;
    text-align: center;
    font-size: 80%
  }

  #progressWrapper {
    display: none;
    position: absolute;
    top: 50%;
    width: 50%;
    transform: translateX(-50%);
    left: 50%;
  }

  .zoomInBuddy {
    font-size: 14px;
    div {
      opacity: 0;
      z-index: 0;

      &.show {
        padding: 10px;
        background: #fff;
        border-radius: 10px;
        z-index: 400;
        position: absolute;
        top: calc($header-height + 10px);
        right: 10px;
        max-width: 80vw;
        @media screen and (min-width: 768px) {
          padding: 20px;
          right: 10px;
          max-width: unset;
        }
        &.op {
          opacity: 1;
        }
      }
    }
  }

  .leaflet-marker-icon {
    &.leaflet-div-icon {
      background: none !important;
      border: none !important;
    }
  }

  .rangeSlider {
    $bg: #434d5a;

    .ui-slider,
    .ui-slider .ui-slider-pip .ui-slider-line {
      background: lighten($bg, 25%);
    }

    .ui-slider .ui-slider-handle .ui-slider-tip:after {
      border-left-color: #434d5a;
    }


    .ui-slider .ui-slider-handle.ui-state-hover,
    .ui-slider .ui-slider-handle.ui-state-focus,
    .ui-slider .ui-slider-handle.ui-state-active { border-color: white; }
  }

  #searchControlContent {
    padding: 10px;
    position: absolute;
    left: 35px;
    top: 0;
    background: #fff;
    margin: 0;
    width: 300px;
    height: 300px;
    border: 2px solid rgba(0,0,0,0.2);
    overflow: hidden;
    transition: height 1s, width 2s, border-width 1.5s;
  }
}

#draggableMap {
  .mapWrapper {
    height: calc(100vh - 334px); // Minus dropzone/inputs / modal dialog margin / modal body padding
  }
}
