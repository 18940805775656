@import '../../styles/base/variables.scss';
@import '../../styles/base/mixins.scss';

@keyframes newsflashMarquee {
  from { transform: translateX(0); }
  to { transform: translateX(calc(-100% - 50vw)); }
}
@keyframes blinkingDot {
  from { background-color: red; }
  to { background-color: rgb(136, 0, 0); }
}

.newsflash {
  @include subheadlineFont;
  color: $dark-blue;
  font-size: rem(20px);
  padding-top: rem(2px);
  height: $newsflash-height;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;

  &__content {
    animation-name: newsflashMarquee;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-delay: 1s;
    display: flex;
    position: absolute;
    left: 210px;
    color: $dark-blue;
    cursor: default;

    &:not([href='#']) {
      cursor: pointer;
    }

    p {
      margin: 0 !important;
    }

    .clone {
      position: absolute;
      left: calc(100% + 50vw);
      // &:first-child() {
      // }
    }
  }

  &__countdown {
    background-size: 100vw rem(44px);
    // position: absolute;
    padding: 0 rem(20px) 0 rem(10px);
    z-index: 1;

    @include media-breakpoint-down(sm) {
      padding-right: rem(10px);
    }
  }

  &__reddot {
    width: rem(10px);
    height: rem(10px);
    background-color: red;
    border-radius: rem(10px);
    margin: 0 rem(10px) rem(2px) rem(5px);
    display: inline-block;
    animation: blinkingDot 800ms alternate infinite;
  }
}
