@import 'styles/base/variables.scss';
@import 'styles/base/mixins.scss';

.pretty-audio {
  &--embed {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__wave-container {
    position: relative;
    background-color: #000;
    mix-blend-mode: lighten;

    .theme-light & {
      mix-blend-mode: difference;
    }

    &::after {
      content: "";
      @include absoluteCover;
      display: block;
      background: linear-gradient(295.79deg, #50E2C1 40.91%, #9039FE 73.54%, #0076FF 95.66%);
      mix-blend-mode: multiply;
      pointer-events: none;
      z-index: 5;

      .theme-light & {
        background: linear-gradient(295.79deg, #B50A4A 40.91%, #4FA40B 73.54%, #D07C11 95.66%);
      }
    }
  }
  &--embed &__wave-container {
    flex: 1;
    padding: rem(15px) 0 rem(5px);
    canvas, wave {
      height: 100% !important;
      max-height: 100px;
    }
  }

  &__controls {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: rem(40px);
    @include headlineFont;
    font-size: 30px;

    button {
      color: inherit;
      display: inline-flex;
      align-items: center;
      line-height: 1;
      padding: 0;

      &:hover {
        color: $focus_scitech;
      }

      span {
        font-size: 30px;
        padding: 7px 0 0 10px;
      }

      svg {
        width: 34px;
        height: 34px;
        margin-left: -5px;
      }
    }
  }
  &--embed &__controls {
    margin-top: 0;
    font-size: 22px;
    align-items: center;
    padding: 0 rem(12px) rem(6px);

    button {
      span {
        display: none;
      }
      svg {
        width: 26px;
        height: 26px;
      }
    }
  }

  &--embed &__time, &--embed &__title {
    line-height: 1;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 18px;
    padding-top: 3px;
    white-space: nowrap;
  }

  &--embed &__title {
    padding-left: 10px;
    padding-right: 20px;
    overflow: hidden;
    text-transform: none;
    flex: 1;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.2;
    }
  }
}