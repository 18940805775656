@import "../base/variables";

.share {
  color: $mid-gray;
  &.share--white {
    color: white;
  }
  &.share--gray {
    color: $mid-gray;
  }
  &.share--dark-gray {
    color: $dark-blue;
  }
  button {
    text-align: right;
    cursor: pointer;
    color: inherit;
    background: none;
    border: none;
    &:focus {
      outline: none;
    }
  }
}
