@import '../../styles/base/variables.scss';

.back-to-top {
  padding: 5px 4px 5px 5px;
  border: none;
  border-radius: 4px;
  display: inline-flex;
  background-color: transparent;
  color: $dark-blue;
  transition: all 200ms ease;

  &:hover {
    svg path {
      fill: $dark-blue;
    }
  }

  svg {
    width: rem(32px);
    height: rem(32px);
    margin-left: 1px;
  }
}