@import '../../styles/base/variables.scss';
@import '../../styles/base/mixins.scss';

.theme-switch {
  width: 44px;
  height: 24px;
  border-radius: 999px;
  background-color: white;
  display: inline-block;
  position: relative;
  cursor: pointer;

  &.light {
    background-color: $focus_scitech;
  }

  &__toggle {
    top: 2px;
    left: 2px;
    width: 20px;
    height: 20px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 999px;
    background-color: $dark-blue;
    transition: all 200ms ease;

    svg {
      width: 70%;
      height: 70%;
    }
  }

  &.light &__toggle {
    color: $focus_scitech;
    background-color: #fff;
    transform: translateX(100%) rotate(90deg);

    svg:last-child {
      display: none;
    }
  }

  &.dark &__toggle {
    svg:first-child {
      display: none;
    }
  }
}