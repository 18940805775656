@import 'styles/base/variables.scss';
@import 'styles/base/mixins.scss';

.playlist {
  @include sansBodyCompactFont;
  font-size: rem(20px);
  max-width: rem(710px);
  
  @include media-breakpoint-down(sm) {
    font-size: rem(16px);
  }

  &--compact {
    max-width: 100%;
    font-size: rem(16px);
  }

  &__table {
    width: 100%;
  }

  &__row {
    display: flex;

    &:first-child {
      align-items: center;
    }

    &:not(:last-child) {
      margin-bottom: rem(30px);
    }

    > div {
      &:first-child {
        width: calc(2ch + rem(28px));
        @include media-breakpoint-down(sm) {
          width: calc(2ch + rem(12px));
        }
      }
      &:nth-child(2) {
        flex: 1;
      }
      &:last-child {
        width: min-content;
        text-align: right;
      }
    }

    a {
      color: #000;
      padding-bottom: rem(2px);
      display: inline-block;

      .theme-dark & {
        color: white;
      }
    }

    &.active {
      > div:first-child, a {
        color: $focus_action;
      }
    }
  }
  &--compact &__row {
    // font-size: 0.9em;
    &:not(:last-child) {
      margin-bottom: rem(12px);
    }
  }

  &__row-number {
    svg {
      position: relative;
      top: rem(-2px);
      left: rem(-4px);
      display: none;
      cursor: pointer;
    }
  }
  &__row:hover &__row-number {
    span {
      display: none;
    }
    svg {
      display: inline-block;
    }
  }

  &__authors {
    font-size: rem(15px);
    @include sansBodyCompactFontLight;
  }
}