@import '../../../base/variables';
@import '../../../base/mixins';

.modal {
  overflow: hidden !important;
}

.modal-dialog {
  color: #000;

  &.showscroll {
    .modal-body {
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, .5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
      }
    }
  }

  &.fullwidth {
    @media (min-width: 720px) {
      max-width: calc(100% - 30px);
    }

    display: flex;
    flex: 1 0 100%;

    .modal-body {
      overflow-y: scroll;
      overflow-x: hidden;
    }
  }

  &.oa-large {
    flex: 1 0 100%;
    max-width: 100%;
    --content-padding: #{rem(32px)};
    --scrollbar-spacing: #{rem(20px)};
    
    @media (min-width: 720px) {
      max-width: min(1160px, calc(100% - 48px));
      --content-padding: #{rem(48px)};
      --scrollbar-spacing: #{rem(20px)};
    }

    .modal-content {
      border: none;
      border-radius: rem(26px);
      padding: var(--content-padding);
      padding-right: calc(var(--content-padding) - var(--scrollbar-spacing));
      height: 100%;
      max-height: calc(100vh - 1rem);
      
      @media (min-width: 720px) {
        border-radius: rem(48px);
        max-height: calc(100vh - 3.5rem);
      }
    }

    .modal-header {
      padding: 0 var(--scrollbar-spacing) rem(20px) 0;
      border: none;
      h5 { font-size: rem(24px) }
    }

    .modal-body {
      overflow-y: auto;
      overflow-x: hidden;
      padding: 0 var(--scrollbar-spacing) 0 0;
    }

    .closeButton {
      top: var(--content-padding);
      right: var(--content-padding);
    }
  }

  &.oa-dialog {
    .modal-content {
      height: auto;
      border: none;
      border-radius: rem(18px);
      padding: rem(5px) rem(10px);
    }
    .modal-title {
      padding-top: rem(4px);
    }
    .modal-body {
      @include sansBodyFont;
      padding: rem(24px) rem(16px);
    }
  }

  &.padded {
    .modal-body {
      @media (min-width: 720px) {
        padding: rem(48px);
      }
    }
  }

  &.auto-height {
    .modal-content {
      height: auto;
    }
  }

  form {
    input, textarea {
      &.form-control {
        border: 1px solid hsl(0,0%,80%);
      }
    }
  }

  .closeButton, .openButton {
    top: rem(8px);
    right: rem(10px);
    width: rem(28px);
    height: rem(28px);
    position: absolute;
    cursor: pointer;

    .os-win & {
      right: rem(25px);
    }
  }

  &.blue {
    color: $soft-gray;

    // Bootstrap default close.
    .close {
      color: #fff;
      span {
        color: #fff;
      }
    }

    .modal-content {
      background-color: $dark-blue;
      border: none;
      border-radius: 0;

      .header {
        background: $dark-blue;
        color: #fff;
        padding: 15px;
        margin: 0;

        a.gray {
          color: $soft-gray;
          font-family: 'Minion Pro', sans-serif;
          font-size: 14px;
          opacity: .5;
          &.openButton {
            line-height: 1.2;
            display: inline-block;
            margin-right: 10px;
            font-size: 17px;
          }

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
}
