@import '../../styles/base/variables.scss';
@import '../../styles/base/mixins.scss';

.highlight-item {
  &--loading {
    color: transparent;
  }

  &__image {
    padding-top: 78%;
    position: relative;
    margin-bottom: 17px;

    img {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      object-fit: cover;
    }

    svg {
      right: rem(15px);
      bottom: rem(10px);
      width: rem(34px);
      height: rem(34px);
      position: absolute;
      color: white;
      transition: color 200ms ease;
    }
    &:hover svg {
      color: $focus_scitech;
    }
  }
  &--loading &__image {
    @include loadingShimmer;
  }

  &__title {
    font-size: 22px;
    margin-bottom: 0;

    a {
      color: inherit !important;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &--loading &__title {
    width: 85%;
    @include loadingShimmer;
  }

  &__summary {
    font-family: Amare Medium, sans-serif;
  }
  &--loading &__summary {
    width: 60%;
    @include loadingShimmer;
  }

  &__description {
    margin-top: rem(10px);
    font-family: Amare Medium, sans-serif;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  &--loading &__description {
    width: 100%;
    @include loadingShimmer;
  }

  &__tags .tag {
    font-size: 14px;
    line-height: 1.2;
  }
  &--loading &__tags .tag {
    width: 30%;
    @include loadingShimmer;
  }

  &__link {
    margin-top: 4px;
    display: inline-block;
    color: inherit !important;

    span {
      text-decoration: underline;
    }
    &:hover span {
      text-decoration: none;
    }
  }
}