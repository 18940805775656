.react-tags-wrapper {
  position: relative;

  .tag-wrapper {

  }

  /* Styles for selected tags */
  .ReactTags__selected {
    span {
      &.ReactTags__tag {
        border: 1px solid #ddd;
        background: #eee;
        font-size: 12px;
        display: inline-block;
        padding: 5px;
        margin: 0 5px;
        cursor: move;
        border-radius: 2px;
      }
    }
    a {
      &.ReactTags__remove {
        color: #aaa;
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }

  .ReactTags__tagInput {
    width: 200px;
    border-radius: 2px;
    display: inline-block;

    input {
      &.ReactTags__tagInputField,
      &.ReactTags__tagInputField:focus {
        height: 31px;
        margin: 0;
        font-size: 12px;
        width: 100%;
        border: 1px solid #eee;
      }
    }
  }

  /* Styles for suggestions */
  .ReactTags__suggestions {
    position: absolute;

    ul {
      list-style-type: none;
      box-shadow: .05em .01em .5em rgba(0, 0, 0, .2);
      background: white;
      width: 200px;

      li {
        border-bottom: 1px solid #ddd;
        padding: 5px 10px;
        margin: 0;

        &.ReactTags__activeSuggestion {
          background: #b7cfe0;
          cursor: pointer;
        }

        mark {
          text-decoration: underline;
          background: none;
          font-weight: 600;
        }
      }
    }
  }

}
