@import '../../styles/base/variables.scss';
@import '../../styles/base/mixins.scss';

.home-carousel {
  height: rem(680px);
  position: relative;
  font-family: Amare Medium, sans-serif;
  overflow: hidden;

  &__slide {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute !important;
    transition: opacity 400ms ease;
    background-position: center;
    background-size: cover;
    opacity: 0;
    pointer-events: none;

    &.active {
      opacity: 1;
      pointer-events: all;
    }

    canvas {
      position: absolute;
      top: 0;
      left: 0;
      // width: 100%;
      // height: 100%;
    }
  }

  &__credits {
    bottom: 20px;
    width: rem(640px);
    left: calc(100% - 10px);
    position: absolute;
    display: inline-block;
    color: $soft-gray;
    // white-space: nowrap;
    font-size: 14px;
    transform: translateX(40px) rotate(-90deg);
    transform-origin: left bottom;
    transition: all 400ms ease;

    .active & {
      transform: rotate(-90deg);
      transition-delay: 200ms;
    }
  }

  &__control {
    right: 0px;
    bottom: 0px;
    // right: 120px;
    // bottom: 90px;

    width: 200px;
    height: 200px;
    position: absolute;
    transform: translate3d(-120px, -90px, 0);
    transition: transform 30s cubic-bezier(.79,.14,.15,.86);

    @include media-breakpoint-only(xs) {
      right: 50%;
      transform: translateX(50%);
    }
  }
}