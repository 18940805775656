@import "../../base/variables";
@import "../../utils/animations";

%searchFont {
  color: #fff;
  opacity: 0.5;
}

#audioPlayerDiv {
  background: $dark-blue;
  color: #fff;

  min-height: 0px;
  max-width: 100vw;

  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100000;

  transition: min-height 1.2s cubic-bezier(0.22, 0.61, 0.36, 1),
    opacity 0.3s linear 0.5s;

  &.open {
    min-height: 100%;
    max-height: 100%;
    transition: min-height 1.2s cubic-bezier(0.22, 0.61, 0.36, 1),
      opacity 0.3s linear 0.5s;
  }
}

.searchWrap {
  height: 100%;
  background: $dark-blue;
  z-index: -1;
}
.wrapHeight {
  min-height: 35px;
}

.fixed {
  position: fixed;
  top: 0;
  left: 0;
}

.noFix {
  position: relative;
}

.stories-page {
  #searchConsole {
    display: none;
  }
}

.story-page {
  #searchConsole {
    transition: 0.15s ease-in-out;
    position: fixed;

    &.open {
      top: 46px;
    }
  }
}

#searchConsole {
  margin-top: -1px;
  background: $dark-blue;
  color: #fff;
  max-width: 100vw;
  width: 100%;
  z-index: 999;
  display: block;
  overflow: hidden;
  transition: min-height 1.2s cubic-bezier(0.22, 0.61, 0.36, 1),
    opacity 0.3s linear 0.5s;

  .search__control--is-disabled .search__placeholder {
    height: 28px;
  }
  .search__control--is-disabled .search__value-container {
    padding-top: 2px !important;
  }

  & a {
    color: inherit;
  }

  .console {
    .legend {
      background: rgba(120, 120, 120, 50);
      height: 0;
      max-height: 200px;
      font-family: 'Minion Pro', sans-serif;
      font-size: 12px;
      overflow: hidden;
      transition: max-height 1s;
      cursor: default;
      @extend %searchFont;
      div[class*="col-"] {
        padding: 15px;
      }
      .border_right {
        border-right: 1px solid $dark-blue;
      }
    }

    .options {
      overflow: hidden;

      .padding {
        padding: 0;
      }

      div[class*="col-"] {
        height: 0;
      }

      .view {
        height: auto !important;
        .line {
          width: 2.5em;
          height: 4px;
          background-color: #fff;
          border-radius: 1em;
          margin-top: 12.5px;
          margin-left: 15px;
        }
      }

      .mid {
        height: 0;
        overflow: hidden;
        .d-flex {
          margin: 0;
          height: 100%;
          width: 100%;
          .inputwrapper {
            height: 35px;
            flex: 1 0 100%;
          }
        }
      }

      .view {
        .row {
          height: 0;
          overflow: hidden;
          .option {
            @extend %searchFont;
            &.active {
              opacity: 1;
            }
          }
        }
      }

      .searchInput {
        height: 100%;
        border-top: 1px solid $dark-blue;
        .search__control {
          height: 100%;
          border: 0;
          border-radius: 0;
          box-shadow: none;
          background: transparent;
          .search__value-container {
            border: 0;
            color: #fff;
            background: rgba(120, 120, 120, 0.5);
            border-radius: 0;
            height: 100%;
            width: 100%;
            padding: 10px;

            .search__placeholder {
              color: #fff;
              padding-left: 2.5px;
            }
            .search__input {
              color: #fff;
            }

            .search__indicators {
              background: rgba(120, 120, 120, 0.5);
              .search__indicator {
                color: #fff;
                &.search__loading-indicator {
                  color: #fff;
                }
              }
            }

            .search__multi-value {
              background: transparent;
              border: 1px solid #fff;
              border-radius: 0;
              .search__multi-value__label {
                color: #fff;
              }
              .search__multi-value__remove {
                &:hover {
                  background-color: transparent;
                  color: #de350b;
                  cursor: pointer;
                }
              }
            }

            &.search__value-container--has-value {
              background: #001218;
            }
          }

          &.search__control--is-focused {
            border: none;
            .search__value-container {
              background: #001218;
            }
          }
        }

        .search__indicators {
          background: #001218;
          .search__indicator {
            color: #fff;
            &.search__loading-indicator {
              span {
                background-color: #fff !important;
              }
            }
          }
        }

        .search__menu {
          z-index: 99999;
          margin: 0;
          border-radius: 0;
          background: rgba(0, 18, 24, 0.95);

          .search__option {
            background: transparent;
            &.search__option--is-focused {
              background: rgba($dark-blue, 0.3);
            }

            .field {
              text-transform: capitalize;
            }
          }
        }
      }

      .icon {
        width: 0;
        overflow: hidden;
        font-size: 16px;
        flex: 0 1 auto;
        text-align: center;
        @extend %searchFont;

        span {
          &.simple-icon-magnifier {
            cursor: pointer;
            color: #fff;
            transform: scaleX(-1);
            display: inline-block;
          }
        }

        .closeButton {
          cursor: pointer;
          height: 0;
          max-width: 0;
          color: #fff;
          opacity: 1;
          overflow: hidden;
          padding: 0;
        }
      }

      // Bubble styling
      //.focus {
      //  min-height: 25px;
      //  padding: 0 !important;
      //  max-width: 100%;
      //
      //  div {
      //    position: absolute;
      //    top: 0;
      //    left: 0;
      //    height: 100%;
      //    width: 100%;
      //    background: linear-gradient(to right, #0076FF 0%, #9013FE 20%, #50E3C2 50%);
      //    transition: left 1s !important;
      //  }
      //}
    }

    // Focus checkboxes
    .focus {
      height: 0;
      font-size: 90%;
      padding-top: 0px !important;
      @media screen and (max-width: 768px) {
        font-size: 85%;
      }
    }

    .focus-open {
      font-size: 90%;
      height: auto;
      padding-top: 9px !important;
      @media screen and (max-width: 768px) {
        font-size: 85%;
      }
    }

    .results {
      height: 0;
      overflow: hidden;
    }

    &.open {
      max-height: 100vh;
      overflow-y: scroll;
      overflow-x: hidden;
      .legend {
        max-height: 0;
        transition: max-height 1s;
      }

      .icon {
        width: auto;
      }

      .options {
        overflow: unset !important;

        .view {
          width: 0;
          .line {
            margin: 0;
            height: 0 !important;
            width: 0 !important;
          }
          .row {
            height: auto;
          }
        }

        .mid {
          overflow: unset !important;
          height: 60px;
          width: 100%;
          position: relative;
          .d-flex {
            .inputwrapper {
              height: 100%;
              flex: auto;
              max-width: calc(90% - 55px);
              @media screen and (min-width: 768px) {
                max-width: unset;
              }
            }
            .icon {
              flex: 0 0 55px;
              width: 55px;
              @media screen and (min-width: 768px) {
                flex: 0 0 100px;
                width: 100px;
              }
            }
          }
        }
        .margin {
          margin: 0 15px;
        }
        .padding {
          padding: 15px;
        }
        .closeButton {
          height: auto;
          max-width: unset;
        }

        .focus {
          max-width: 0;
          overflow: hidden;
          transition: max-width 1.2s;

          div {
            left: 100% !important;
            transition: left 1s !important;
          }
        }
      }

      .tagList {
        padding-bottom: 10px !important;
        @media screen and (max-width: 768px) {
          width: auto;
          height: 45px;
          overflow-x: scroll;
          white-space: nowrap;
          -webkit-overflow-scrolling: touch;
        }
        .tagWrapper {
          display: inline-block !important;
          padding-top: 10px;
          &:first-child {
            margin-left: 1px;
          }
        }
      }
    }

    &.hover {
      .legend {
        height: auto;
      }

      .options {
        .padding {
          padding: 15px;
        }

        div[class*="col-"] {
          height: auto;
        }

        .opacity5 {
          @extend %searchFont;
        }

        input.searchInput {
          border-top: 1px solid $dark-blue;
        }

        .view {
          width: 0;
          .line {
            height: 0;
            margin-top: 0;
          }
          .option {
            cursor: pointer;
            &:hover {
              opacity: 1;
            }
          }
        }

        .view .row,
        .mid {
          height: auto;
        }
      }
    }

    .results {
      position: relative;
      overflow: hidden;
      height: 0;
      /*padding-top: 20px;*/
      .result {
        cursor: pointer;
        position: relative;
        max-height: 200px;
        overflow: hidden;
        border-bottom: 1px solid #fff;
        padding-bottom: 5px;
        margin-bottom: 5px;
        .title {
          font-size: 'Amare Alta';
        }
        .creators {
          font-family: 'Amare Medium';
          overflow: hidden;
          max-width: 95%;
          span.ellipsis {
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 100%;
            overflow: hidden;
            display: inline-block;
          }
          .title {
            flex: 1 1 50%;
          }
        }
        .pdf {
          position: relative;
          max-width: 200px;
          canvas {
            width: 100% !important;
            height: auto !important;
          }
        }

        .profile-image {
          width: 100%;
          height: 100%;
          background: $item-details-border;
        }
      }
    }
  }

  &.open {
    /*min-height: 100%;*/
    /*max-height: 100%;*/
    transition: min-height 1.2s cubic-bezier(0.22, 0.61, 0.36, 1),
      opacity 0.3s linear 0.5s;
  }
}

.modal {
  .search {
    .image {
      img {
        width: auto;
        max-height: 70vh !important;
      }
    }
  }
}
