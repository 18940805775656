@import '../../base/variables';
@import '../../base/mixins.scss';

.itemEditor, .collectionEditor {
  position: relative;
  width: 100%;

  input, textarea{
    &.form-control {
      border: 1px solid hsl(0,0%,80%);
    }
  }
}

.modal-content {
  height: 94vh;
}

.raw-HTML-editor {
  height: 150px !important;
  font-size: 10pt !important;
  border-radius: 0 !important;
  border-top: 0 !important;

  &:focus {
    outline: none !important;
  }
}

.collectionEditor {
  .collapse {
    width: 100%
  }

}

.focusSelect {
  display: flex;
  white-space: nowrap;

  @media screen and (max-width: 768px) {
    margin-bottom: 47px;
  }

  .form-group {
    display: flex;
    align-items: center;
    margin-right: 30px;

    label {
      margin: 0;
      order: 1;
    }
  }
}

.itemsTab {
  height: 400px !important;
}

.nav-link {
  cursor: pointer;
}

.item-thumbnails {
  row-gap: 30px;
}

.item-thumbnail {
  position: relative;
  cursor: pointer;
  @include sansBodyCompactFont;

  &.active {
    outline: 5px solid $focus_scitech;
    outline-offset: -5px;
  }

  .remove {
    top: 15px;
    right: 15px;
    position: absolute;
    display: none;
    cursor: pointer;

    &:hover {
      fill: $focus_scitech;
    }
  }
  &:hover .remove {
    display: inline-block;
  }

  &.webarchive, &.videoembed {
    height: 70px;
    display: flex;
    align-items: center;
    padding: 0 12px;
    column-gap: 10px;

    img {
      height: 60%;
      width: auto;
    }

    .remove {
      top: 22px;
    }
  }
}
