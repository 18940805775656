@import '../../styles/base/variables.scss';

.home-explore {
  h2 {
    position: relative;

    .back-to-top {
      top: 45%;
      right: var(--page-margin);
      transform: translateY(-50%);
      position: absolute;
    }
  }
}