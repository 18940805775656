.audioPreview {
  cursor: pointer;
  position: relative;
  z-index: 0;

  .content {
    position: absolute;
    z-index: 4;
    width: 100%;
    height: 100%;
    top: 50%;
    transform: translateY(-50%);
    color: #000;
    align-items: center;

    .info {
      max-height: 95%;
      overflow: hidden;
    }

    .background {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 60%;
      z-index: -1;
      background: linear-gradient(to right, rgba(255,255,255,1) 40%,rgba(255,255,255,0) 100%);
    }

    .play {
      line-height: 0;
      padding: 0 15px;
    }

    .title {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

}


.audioPlayer {
  transition: max-height 1s;
  max-height: 60px;
  .container-fluid {
    position: relative;

    .overlay {
      &.false {
        display: none;
      }
      &.true {
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 100%;
        z-index: 100000;
        .middle {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          .spinner-grow {
            color: #fff;
            height: 50px;
            width: 50px;
          }
        }
      }
    }
  }
  &.hide {
    max-height: 0;
    overflow: hidden;
  }
  .row {
    padding: 5px 15px;
    align-items: center;
  }
  .duration {
    color: #787878;
    font-size: 14px;
  }
  .closeButton, .openButton a, .openButton {
    line-height: 0;
    cursor: pointer;
    color: #D8D8D8;
    &:hover {
      color: #fff;
    }
  }
  .control {
    line-height: 0;
    cursor: pointer;
    color: #787878;
    &:hover {
      color: #fff;
    }
  }
}
