@import '../../../styles/base/variables';
@import '../../../styles/base/mixins';

.cropper {
  &__upload {
    margin-bottom: rem(16px);
    border: 1px solid $soft-gray;
    // border-radius: 999px;
    
    input {
      padding: rem(16px);
    }
  }

  &__canvas-wrapper {
    aspect-ratio: 1;
  }

  .modal-content {
    height: auto;
    min-height: rem(400px);
    max-height: 94vh;
  }

  .modal-body {
    height: 100%;
    max-height: 94vh;
    display: flex;
    flex-direction: column;
  }
}

