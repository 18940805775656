@import '../base/variables.scss';
@import '../base/mixins.scss';

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.absolute-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.flex-even {
  flex: 1;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: start;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.header-button-container {
  margin-right: 0.7rem;
  margin-left: 0.7rem;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
}

.background {
  &-white {
    background-color: white;
  }
  &-dark {
    color: white;
    background-color: $dark-blue;
  }
  &-rainbow {
    background-image: linear-gradient(95.32deg, rgba(0, 118, 255, 0.3) 1.02%, rgba(80, 226, 193, 0.3) 50.39%, rgba(144, 57, 254, 0.3) 108.75%);
    background-color: white;
  }
  &-violet {
    background-color: $soft-violet;
  }
}

.theme-colors {
  .theme-dark &,
  .theme-light .theme-dark & {
    color: $soft-gray;
    background-color: $dark-blue;
  }
  .theme-light &,
  .theme-dark .theme-light & {
    color: inherit;
    background-color: white;
    // background-image: linear-gradient(95.32deg, rgba(0, 118, 255, 0.3) 1.02%, rgba(80, 226, 193, 0.3) 50.39%, rgba(144, 57, 254, 0.3) 108.75%);
    background-image: linear-gradient(153.16deg, #50E2C126 5.91%, #9039FE26 60.55%, #0076FF26 91.92%);
  }
}

.text-md-center {
  @include media-breakpoint-up(md) {
    text-align: center !important;
  }
}

.text-main {
  font-size: 34px;
  font-family: Amare Medium, sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.text-title {
  font-size: 30px;
  font-family: Amare Medium, sans-serif;
}

.text-subtitle {
  font-size: 22.5px !important;
  font-family: Amare Medium, sans-serif;
}

.text-lead {
  font-size: 20px !important;
  font-family: Amare Medium, sans-serif;
}

.text-alta {
  font-family: Amare Alta, sans-serif;
}

.text-error {
  color: #dc3545;
}

.text-help {
  font-size: rem(14px) !important;
  font-family: Amare Alta, sans-serif;
  opacity: 0.6;
}

.text-clamp {
  &-4 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    line-clamp: 4;
  }
  &-5 {
    overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    line-clamp: 5;
  }
}

.desktop-only {
  @media screen and (max-width: 720px) {
    display: none !important;
  }
}
.lg-only {
  @media screen and (max-width: 720px) {
    display: none !important;
  }
}

.mobile-only {
  @media screen and (min-width: 721px) {
    display: none !important;
  }
}

.header-margin {
  margin-top: 3.75rem;
}

.page-margin {
  margin-left: var(--page-margin);
  margin-right: var(--page-margin);
}

.opacity {
  &-30 { opacity: 0.3; }
  &-50 { opacity: 0.5; }
}

.no-events {
  pointer-events: none;
}

.action-circle {
  background-color: #0000005e;
  border-radius: 200px;
  padding: 25px;

  &:hover {
    color: $focus_scitech;
  }
}

.hover-highlight {
  &:hover {
    color: $focus_scitech;
  }
}

.hidden-scrollbar {
  scrollbar-width: none; // FF
  &::-webkit-scrollbar { // WebKit
    width: 0;
    height: 0;
  }
}

.sticky-top {
  position: sticky;
  top: calc($header-height + 20px);

  .modal & {
    top: 15px;
  }
}

.w-max-1024 {
  max-width: 1024px;
}
.w-max-900 {
  max-width: 900px;
}
.w-max-500 {
  max-width: 500px;
}